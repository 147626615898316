import React, { useState } from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

import PageHeader from '../../components/page-header'
import Strip from '../../components/strip'
import NewsletterSignup from '../../components/newsletter-signup'
import ColorSwitch from '../../components/waypoint-color-switch'
import ArrowForward from '../../components/Icons/ArrowForward'
import LinkCard from '../../components/link-card'
import { useIsFrench } from '../../hooks/use-is-language'

const BlogList = ({ posts, links }) => {
  const [seeAllPosts, setSeeAllPosts] = useState(false)
  const initialDisplayQuantity = 10

  const [t] = useTranslation()

  const isFrench = useIsFrench()

  return (
    <React.Fragment>
      <section className="s-container s-page__hero s-page__hero--short">
        <PageHeader
          lede={t('BlogList.heading')}
          subheading={t('BlogList.subheading')}
        />
      </section>
      <section className="perspectives__list s-container">
        {posts.map((post, index) => {
          if ((!seeAllPosts && index < initialDisplayQuantity) || seeAllPosts) {
            return (
              <Strip
                side={t('WritingPage.' + post.data.category)}
                largePad
                key={index}
              >
                <Link
                  to={`/writing/${post.data.slug}`}
                  className="no-underline perspectives__list__heading"
                >
                  <h4 className="smaller-for-mobile">{post.data.title}</h4>
                </Link>
                <div
                  className="color-secondary"
                  dangerouslySetInnerHTML={{ __html: post.data.summary.html }}
                />
                <Link
                  to={`/writing/${post.data.slug}`}
                  className="no-underline"
                >
                  {t('BlogList.keepReading')} <ArrowForward />
                </Link>
              </Strip>
            )
          }
          return null
        })}
      </section>
      <section className="perspectives__button s-container">
        {!seeAllPosts && posts.length > initialDisplayQuantity && (
          <button type="button" onClick={() => setSeeAllPosts(true)}>
            {t('BlogList.loadMore')}
          </button>
        )}
      </section>
      <ColorSwitch transitionColor="black" previousColor="white" />
      {/* <NewsletterSignup
        heading={t('HomePage.LatestNewsSection.lede')}
        lede={t('HomePage.LatestNewsSection.heading')}
        placeholder={t('HomePage.LatestNewsSection.placeholder')}
        optIn={t('HomePage.LatestNewsSection.optIn')}
      >
        {isFrench ? null : (
          <div className="perspectives__link-cards grid grid--3col">
            {links.map((link, index) => (
              <LinkCard key={index} card={link} showPhoto={false} />
            ))}
          </div>
        )}
      </NewsletterSignup> */}
    </React.Fragment>
  )
}

export default BlogList
