import React from 'react'
import { graphql } from 'gatsby'
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import BlogList from '../templates/blog-list'

require('../scss/perspectives.scss')

function PerspectivesPage({ data }) {
  const posts = data.allPrismicBlogPost.edges.map(post => post.node)
  const links = data.allPrismicRecentLinks.edges[0].node.data.links

  const [t, { language }] = useTranslation(['translations', 'seo'])

  return (
    <Layout startingClass="color-switch--white">
      <Helmet
        title={t('HomePage.title', { ns: 'seo' })}
        meta={[
          {
            name: 'description',
            content: t('HomePage.description', { ns: 'seo' }),
          },
          {
            name: 'keywords',
            content: t('HomePage.keywords', { ns: 'seo' }),
          },
          {
            property: 'og:title',
            content: t('HomePage.ogTitle', { ns: 'seo' }),
          },
          {
            property: 'og:description',
            content: t('HomePage.ogDescription', { ns: 'seo' }),
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-index.jpg',
          },
          {
            property: 'og:url',
            content: 'https://versett.com/writing/',
          },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Versett' },
        ]}
      >
        <html lang={language} className="perspectives" />
      </Helmet>
      <BlogList posts={posts} links={links} />
    </Layout>
  )
}

export const query = graphql`
  query PerspectiveQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicBlogPost(
      sort: { fields: first_publication_date, order: DESC }
      filter: { lang: { eq: $language } }
    ) {
      edges {
        node {
          first_publication_date
          id
          data {
            slug
            title
            summary {
              html
            }
            category
          }
        }
      }
    }
    allPrismicRecentLinks(filter: { lang: { eq: $language } }) {
      edges {
        node {
          data {
            links {
              description
              source
              url
              photo {
                url
                thumbnails {
                  link_300_1x {
                    url
                  }
                  link_300_2x {
                    url
                  }
                  link_500_1x {
                    url
                  }
                  link_500_2x {
                    url
                  }
                  link_700_1x {
                    url
                  }
                  link_700_2x {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PerspectivesPage
