import React from 'react'

import Block from '../waypoint-block'

const Card = ({ classes, children }) => {
  return (
    <Block>
      <article className={`c-card ${classes}`}>{children}</article>
    </Block>
  )
}

export default Card
