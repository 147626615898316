import React from 'react'
import Card from '../card'
import ForwardArrow from '../Icons/ArrowForward'

const LinkCard = ({ card, showPhoto = true }) => {
  return (
    <div className="c-link-card">
      <Card>
        <a href={card.url}>
          {showPhoto && (
            <picture>
              <source
                sizes="(min-width: 1220px) calc(32.36vw - 89px), (min-width: 780px) calc(48.1vw - 90px), calc(100vw - 62px)"
                srcSet={`${card.photo.thumbnails.link_300_1x.url} 300w,
                ${card.photo.thumbnails.link_500_1x.url} 500w,
                ${card.photo.thumbnails.link_300_2x.url} 600w,
                ${card.photo.thumbnails.link_700_1x.url} 700w,
                ${card.photo.thumbnails.link_500_2x.url} 1000w,
                ${card.photo.thumbnails.link_700_2x.url} 1400w,`}
              />
              <img src={card.photo.url} alt={card.description} />
            </picture>
          )}
          <p>{card.source}</p>
          <p>{card.description}</p>
          <ForwardArrow />
        </a>
      </Card>
    </div>
  )
}

export default LinkCard
