import React from 'react'
import classnames from 'classnames'
import Block from '../waypoint-block'

const Strip = ({ side, children, largePad = false }) => {
  return (
    <Block>
      <figure
        className={classnames('grid', 'grid--2col-1-2', 'c-strip', {
          'c-strip--large-padding': largePad,
        })}
      >
        <div
          className={classnames('color-secondary', {
            'c-strip__number': !isNaN(side),
          })}
        >
          {side}
        </div>
        <div>{children}</div>
      </figure>
    </Block>
  )
}

export default Strip
